// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-en-js": () => import("./../../../src/pages/AboutUsEn.js" /* webpackChunkName: "component---src-pages-about-us-en-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/AboutUs.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-business-ai-en-js": () => import("./../../../src/pages/business/AiEn.js" /* webpackChunkName: "component---src-pages-business-ai-en-js" */),
  "component---src-pages-business-ai-js": () => import("./../../../src/pages/business/Ai.js" /* webpackChunkName: "component---src-pages-business-ai-js" */),
  "component---src-pages-business-bems-en-js": () => import("./../../../src/pages/business/BemsEn.js" /* webpackChunkName: "component---src-pages-business-bems-en-js" */),
  "component---src-pages-business-bems-js": () => import("./../../../src/pages/business/Bems.js" /* webpackChunkName: "component---src-pages-business-bems-js" */),
  "component---src-pages-business-elevator-en-js": () => import("./../../../src/pages/business/ElevatorEn.js" /* webpackChunkName: "component---src-pages-business-elevator-en-js" */),
  "component---src-pages-business-elevator-js": () => import("./../../../src/pages/business/Elevator.js" /* webpackChunkName: "component---src-pages-business-elevator-js" */),
  "component---src-pages-business-escalator-en-js": () => import("./../../../src/pages/business/EscalatorEn.js" /* webpackChunkName: "component---src-pages-business-escalator-en-js" */),
  "component---src-pages-business-escalator-js": () => import("./../../../src/pages/business/Escalator.js" /* webpackChunkName: "component---src-pages-business-escalator-js" */),
  "component---src-pages-business-hvac-en-js": () => import("./../../../src/pages/business/HvacEn.js" /* webpackChunkName: "component---src-pages-business-hvac-en-js" */),
  "component---src-pages-business-hvac-js": () => import("./../../../src/pages/business/Hvac.js" /* webpackChunkName: "component---src-pages-business-hvac-js" */),
  "component---src-pages-business-logistics-en-js": () => import("./../../../src/pages/business/LogisticsEn.js" /* webpackChunkName: "component---src-pages-business-logistics-en-js" */),
  "component---src-pages-business-logistics-js": () => import("./../../../src/pages/business/Logistics.js" /* webpackChunkName: "component---src-pages-business-logistics-js" */),
  "component---src-pages-business-parking-tower-en-js": () => import("./../../../src/pages/business/ParkingTowerEn.js" /* webpackChunkName: "component---src-pages-business-parking-tower-en-js" */),
  "component---src-pages-business-parking-tower-js": () => import("./../../../src/pages/business/ParkingTower.js" /* webpackChunkName: "component---src-pages-business-parking-tower-js" */),
  "component---src-pages-business-si-en-js": () => import("./../../../src/pages/business/SiEn.js" /* webpackChunkName: "component---src-pages-business-si-en-js" */),
  "component---src-pages-business-si-js": () => import("./../../../src/pages/business/Si.js" /* webpackChunkName: "component---src-pages-business-si-js" */),
  "component---src-pages-contact-en-js": () => import("./../../../src/pages/ContactEn.js" /* webpackChunkName: "component---src-pages-contact-en-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/Contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-elsa-en-js": () => import("./../../../src/pages/ElsaEn.js" /* webpackChunkName: "component---src-pages-elsa-en-js" */),
  "component---src-pages-elsa-js": () => import("./../../../src/pages/Elsa.js" /* webpackChunkName: "component---src-pages-elsa-js" */),
  "component---src-pages-en-js": () => import("./../../../src/pages/en.js" /* webpackChunkName: "component---src-pages-en-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-modal-modal-js": () => import("./../../../src/pages/modal/Modal.js" /* webpackChunkName: "component---src-pages-modal-modal-js" */),
  "component---src-pages-press-en-js": () => import("./../../../src/pages/PressEn.js" /* webpackChunkName: "component---src-pages-press-en-js" */),
  "component---src-pages-press-js": () => import("./../../../src/pages/Press.js" /* webpackChunkName: "component---src-pages-press-js" */),
  "component---src-pages-robot-en-js": () => import("./../../../src/pages/RobotEn.js" /* webpackChunkName: "component---src-pages-robot-en-js" */),
  "component---src-pages-robot-js": () => import("./../../../src/pages/Robot.js" /* webpackChunkName: "component---src-pages-robot-js" */),
  "component---src-pages-standard-group-stand-en-js": () => import("./../../../src/pages/standard/GroupStandEn.js" /* webpackChunkName: "component---src-pages-standard-group-stand-en-js" */),
  "component---src-pages-standard-group-stand-js": () => import("./../../../src/pages/standard/GroupStand.js" /* webpackChunkName: "component---src-pages-standard-group-stand-js" */),
  "component---src-pages-standard-inter-stand-en-js": () => import("./../../../src/pages/standard/InterStandEn.js" /* webpackChunkName: "component---src-pages-standard-inter-stand-en-js" */),
  "component---src-pages-standard-inter-stand-js": () => import("./../../../src/pages/standard/InterStand.js" /* webpackChunkName: "component---src-pages-standard-inter-stand-js" */),
  "component---src-pages-standard-inter-stand-y-4420-en-js": () => import("./../../../src/pages/standard/InterStandY4420En.js" /* webpackChunkName: "component---src-pages-standard-inter-stand-y-4420-en-js" */),
  "component---src-pages-standard-inter-stand-y-4420-js": () => import("./../../../src/pages/standard/InterStandY4420.js" /* webpackChunkName: "component---src-pages-standard-inter-stand-y-4420-js" */),
  "component---src-pages-standard-nation-stand-en-js": () => import("./../../../src/pages/standard/NationStandEn.js" /* webpackChunkName: "component---src-pages-standard-nation-stand-en-js" */),
  "component---src-pages-standard-nation-stand-js": () => import("./../../../src/pages/standard/NationStand.js" /* webpackChunkName: "component---src-pages-standard-nation-stand-js" */),
  "component---src-pages-standard-tta-stand-en-js": () => import("./../../../src/pages/standard/TtaStandEn.js" /* webpackChunkName: "component---src-pages-standard-tta-stand-en-js" */),
  "component---src-pages-standard-tta-stand-js": () => import("./../../../src/pages/standard/TtaStand.js" /* webpackChunkName: "component---src-pages-standard-tta-stand-js" */),
  "component---src-templates-news-post-js": () => import("./../../../src/templates/news-post.js" /* webpackChunkName: "component---src-templates-news-post-js" */)
}

